import React, { createContext, useContext, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import auth from "../services/auth";
import businessService from "../services/businessService";
import { useLocalStorage } from "./useLocalStorage";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
	const [user, setUser] = useLocalStorage("user", null);
	const navigate = useNavigate();

	React.useEffect(() => {
		refreshBusiness();
	}, []);

	function refreshBusiness() {
		if (user?.business?.id) {
			businessService.getDetail().then((response) => {
				if (response.data?.id) {
					user.business = response.data;
					setUser({ ...user });
				}
			});
		}
	}

	const login = async (data) => {
		let response = await auth.login(data.user_name, data.password);
		if (response.data) {
			setUser(response.data);
			navigate("/", { replace: true });
		} else {
			alert("Username/Password invalid!");
		}
	};

	const logout = () => {
		setUser(null);
		navigate("/", { replace: true });
	};

	const value = useMemo(
		() => ({
			user,
			login,
			logout,
		}),
		[user]
	);

	return (
		<AuthContext.Provider value={value}>{children}</AuthContext.Provider>
	);
};

export const useAuth = () => {
	return useContext(AuthContext);
};
