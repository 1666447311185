import React from "react";

import {
	Container,
	Box,
	TextField,
	Button,
	Typography,
	Card,
	CardContent,
	CardMedia,
	Grid,
	CardActionArea,
	Avatar,
	Divider,
	Dialog,
	DialogTitle,
	List,
	ListItem,
	ListItemAvatar,
	ListItemText,
	IconButton,
	TableCell,
	TableBody,
	TableRow,
	TableHead,
	Paper,
	TableContainer,
	Table,
} from "@material-ui/core";

import {
	ArrowBack,
	TaskAltOutlined,
	Delete,
	DoneAllOutlined,
} from "@material-ui/icons";

import { useAuth } from "../../hooks/useAuth";
import { Navigate } from "react-router-dom";

import businessService from "../../services/businessService";
import appHelper from "../../helper/appHelper";
// import { isMobile } from "react-device-detect";
import config from "../../services/config";
import customerService from "../../services/customerService";
import orderService from "../../services/orderService";
import _ from "lodash";

const Shop = () => {
	const { user } = useAuth();
	const [services, setServices] = React.useState([]);
	const [employees, setEmployees] = React.useState([]);

	const [pickedService, setPickedService] = React.useState({});
	const [pickedEmployee, setPickedEmployee] = React.useState({});

	const [customer, setCustomer] = React.useState({});
	const [customerName, setCustomerName] = React.useState("");
	const [customerPhone, setCustomerPhone] = React.useState("");

	const [openEmployeeDialog, setOpenEmployeeDialog] = React.useState(false);
	const [listPickedService, setListPickedService] = React.useState([]);
	const [currentStep, setCurrentStep] = React.useState(1);
	const [totalPrice, setTotalPrice] = React.useState(0);
	const [servicesWithCatalogue, setServicesWithCatalogue] = React.useState(
		[]
	);
	const [catalogues, setCatalogues] = React.useState([]);

	const business_id = appHelper.getBusinessInfo("business_id");

	React.useEffect(() => {
		getServices();
		getAllCatalogue();
	}, []);

	if (!user) {
		return <Navigate to="/login" />;
	}

	function getServices() {
		businessService.getServices(business_id).then((response) => {
			if (response.data) {
				let data = response.data;
				data = _(data)
					.groupBy((ele) => ele.catalogue)
					.map((value, key) => ({ catalogue: key, services: value }))
					.value();
				setServicesWithCatalogue(data);
				setServices(response.data);
			} else {
				alert(response.error);
			}
		});
	}

	function getEmployees(service_id) {
		businessService.getEmployeeService(service_id).then((response) => {
			if (response.data) {
				setEmployees(response.data);
			} else {
				alert(response.error);
			}
		});
	}

	function onPickService(service, isPicked) {
		if (!isPicked) {
			setPickedService(service);
			getEmployees(service.id);
			setOpenEmployeeDialog(true);
		} else {
			if (window.confirm("Remove this service?")) {
				let index = listPickedService.findIndex(
					(ele) => ele.service_id == service.id
				);
				listPickedService.splice(index, 1);
				setListPickedService(Array.from(listPickedService));
				setServices(Array.from(services));
			}
		}
	}

	function onPickEmployee(employee) {
		let temp = Array.from(listPickedService);
		let orderItem = {
			employee_id: employee.id,
			employee_name: employee.full_name,
			employee_image: employee.image,
			service_id: pickedService.id,
			service_name: pickedService.service_name,
			price: pickedService.price,
			service_image: pickedService.image,
		};
		temp.push(orderItem);
		setListPickedService(temp);
		setOpenEmployeeDialog(false);
		setTotalPrice(getTotalPrice(temp));
	}

	function isServicePicked(service) {
		let check = false;
		listPickedService.forEach((element) => {
			if (element.service_id == service.id) {
				check = true;
			}
		});

		return check;
	}

	function confirmServices() {
		setCurrentStep(2);
	}

	function getTotalPrice(services) {
		let total = 0;
		services.forEach((element) => {
			total = total + element.price;
		});
		return total;
	}

	function getCustomerByPhone(phone) {
		customerService.getDetail(phone).then((response) => {
			if (response.data) {
				setCustomer(response.data);
				setCustomerName(response.data.name);
			}
		});
	}

	function onInputCusomterPhone() {
		getCustomerByPhone();
	}

	function onCreateOrder() {
		if (!customerName) {
			alert("Please input your name!");
		}
		let body = {
			customer_id: customer.id,
			business_id: business_id,
			customer_name: customerName,
			customer_phone: customerPhone,
			order_items: listPickedService,
			amount: totalPrice,
		};

		orderService.create(body).then((response) => {
			console.log(response);
			if (!response.error && response.data) {
				setCurrentStep(3);
				setTimeout(function () {
					window.location.reload();
				}, 5000);
			}
		});
	}

	function getAllCatalogue() {
		businessService.getAllServiceCatalogue().then((response) => {
			if (!response.error) {
				setCatalogues(response.data);
			} else {
				alert(response.error);
			}
		});
	}

	function getCatalogueName(catalogue) {
		if (!catalogue) return "";
		let obj = catalogues.find((ele) => ele.id == catalogue);
		if (!obj) return "";
		return obj.name;
	}

	return (
		<Container className="shop" component="main">
			<Box
				sx={{
					marginTop: 5,
					display: "flex",
					flexDirection: "column",
					alignItems: "center",
				}}>
				<img width={300} src={appHelper.getAppLogo()} />
			</Box>
			{currentStep == 1 && (
				<Box>
					<Box
						sx={{
							display: "flex",
							alignItems: "center",
							mb: 6,
						}}>
						<Typography
							sx={{ flex: 2 }}
							gutterBottom
							variant="h1"
							component="div">
							{"Choose services"}
						</Typography>

						<Button
							sx={{ flex: 1, height: 50 }}
							onClick={() => {
								confirmServices();
							}}
							variant="contained"
							color="secondary">
							<Typography variant="h3" component="div">
								{"Confirm"}
							</Typography>
						</Button>
					</Box>
					{servicesWithCatalogue.map((item) => (
						<Box
							key={item.catalogue}
							sx={{
								mt: 2,
								mb: 2,
							}}>
							<Typography variant="h3" sx={{ mb: 2 }}>
								{item.catalogue > 0
									? getCatalogueName(item.catalogue)
									: "Uncatalogued"}
							</Typography>
							<Box
								sx={{
									overflow: "hidden",
									overflowX: "scroll",
								}}>
								<Box
									sx={{
										display: "inline-flex",
									}}>
									{item.services
										?.filter((ele) => ele.is_active)
										.map((service) => (
											<Card
												sx={{
													padding: 0,
													width: 240,
												}}
												onClick={() => {
													onPickService(
														service,
														isServicePicked(service)
													);
												}}>
												<CardActionArea>
													<CardMedia
														component="img"
														height="170"
														width="170"
														image={appHelper.getImage(
															service.image
														)}
														alt="green iguana"
													/>
													<CardContent>
														<Box
															sx={{
																display: "flex",
															}}>
															<Box
																sx={{
																	flex: 5,
																}}>
																<Typography
																	gutterBottom
																	variant="h5"
																	component="div">
																	{
																		service.service_name
																	}
																</Typography>
																<Typography
																	variant="body2"
																	color="text.secondary">
																	{"£ " +
																		service.price}
																</Typography>
															</Box>
															{isServicePicked(
																service
															) && (
																<TaskAltOutlined
																	sx={{
																		color: "green",
																		flex: 1,
																	}}
																/>
															)}
														</Box>
													</CardContent>
												</CardActionArea>
											</Card>
										))}
								</Box>
							</Box>

							{/* <Grid
								container
								spacing={{ xs: 1, md: 2 }}
								columns={{ xs: 2, sm: 8, md: 12 }}>
								{item.services
									?.filter((ele) => ele.is_active)
									.map((service) => (
										<Grid
											item
											xs={2}
											sm={4}
											md={4}
											key={service.id}>
											<Card
												onClick={() => {
													onPickService(
														service,
														isServicePicked(service)
													);
												}}>
												<CardActionArea>
													<CardMedia
														component="img"
														height="170"
														width="170"
														image={appHelper.getImage(
															service.image
														)}
														alt="green iguana"
													/>
													<CardContent>
														<Box
															sx={{
																display: "flex",
															}}>
															<Box
																sx={{
																	flex: 5,
																}}>
																<Typography
																	gutterBottom
																	variant="h5"
																	component="div">
																	{
																		service.service_name
																	}
																</Typography>
																<Typography
																	variant="body2"
																	color="text.secondary">
																	{"£ " +
																		service.price}
																</Typography>
															</Box>
															{isServicePicked(
																service
															) && (
																<TaskAltOutlined
																	sx={{
																		color: "green",
																		flex: 1,
																	}}
																/>
															)}
														</Box>
													</CardContent>
												</CardActionArea>
											</Card>
										</Grid>
									))}
							</Grid> */}
						</Box>
					))}
				</Box>
			)}
			{currentStep == 2 && (
				<Box>
					<Box
						onClick={() => {
							setCurrentStep(1);
						}}
						sx={{
							display: "flex",
							alignItems: "center",
							padding: 3,
							cursor: "pointer",
						}}>
						<ArrowBack />
						<Typography
							sx={{ marginLeft: 2 }}
							variant="h2"
							component="div">
							{"Back"}
						</Typography>
					</Box>
					<TableContainer component={Paper}>
						<Table
							sx={{ minWidth: 700 }}
							aria-label="spanning table">
							<TableHead>
								<TableRow>
									<TableCell>No.</TableCell>
									<TableCell>Service</TableCell>
									<TableCell>Technician</TableCell>
									<TableCell align="right">Price</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{listPickedService.map((row, index) => (
									<TableRow key={row.service_id}>
										<TableCell>{index + 1}</TableCell>
										<TableCell>
											{row.service_name}
										</TableCell>
										<TableCell>
											{row.employee_name}
										</TableCell>
										<TableCell align="right">
											{"£ " + row.price}
										</TableCell>
									</TableRow>
								))}

								<TableRow>
									<TableCell colSpan={3}>Total</TableCell>
									<TableCell align="right">
										{"£ " + totalPrice}
									</TableCell>
								</TableRow>
							</TableBody>
						</Table>
					</TableContainer>
					<Divider sx={{ mt: 5, mb: 5 }} />
					<Box>
						<Typography
							sx={{ mb: 3 }}
							gutterBottom
							variant="h5"
							component="div">
							{"Your information"}
						</Typography>
						<TextField
							label="Your Phone Number"
							type="number"
							name="customer_phone"
							variant="outlined"
							fullWidth
							value={customerPhone}
							onChange={(e) => {
								setCustomerPhone(e.target.value);
							}}
							onBlur={() => {
								getCustomerByPhone(customerPhone);
							}}
							sx={{
								mb: 2,
							}}
						/>
						<TextField
							label="Your Name"
							type="name"
							name="customer_name"
							variant="outlined"
							fullWidth
							required
							value={customerName}
							onChange={(e) => {
								setCustomerName(e.target.value);
							}}
							sx={{
								mb: 2,
							}}
						/>
					</Box>
					<Box
						sx={{
							display: "flex",
							mt: 5,
							align: "center",
						}}>
						<Button
							sx={{ flex: 1, height: 60 }}
							onClick={() => {
								onCreateOrder();
							}}
							variant="contained"
							color="success">
							<Typography variant="h3" component="div">
								{"Place Order"}
							</Typography>
						</Button>
					</Box>
				</Box>
			)}
			{currentStep == 3 && (
				<Box
					sx={{
						marginTop: 5,
						display: "flex",
						flexDirection: "column",
						alignItems: "center",
					}}>
					<Typography
						sx={{ margin: 7, fontSize: 50 }}
						variant="h1"
						color="green">
						{"Order Complete!"}
					</Typography>
					<img
						width={300}
						src={require("../../assets/images/thanks_order.png")}
					/>
				</Box>
			)}
			<Dialog
				onClose={() => {
					setOpenEmployeeDialog(false);
				}}
				open={openEmployeeDialog}>
				<DialogTitle>Choose technician</DialogTitle>
				<Box sx={{ minWidth: 300 }}>
					<List sx={{ pt: 0 }}>
						{employees.map((employee) => (
							<ListItem
								button
								onClick={() => onPickEmployee(employee)}
								key={employee.id}>
								<ListItemAvatar>
									<Avatar
										src={appHelper.getImage(employee.image)}
										sx={{}}
									/>
								</ListItemAvatar>
								<ListItemText
									primary={employee.full_name}
									secondary={
										employee.number_order_item > 0
											? employee.number_order_item +
											  " remain"
											: "Ready"
									}
								/>
							</ListItem>
						))}
					</List>
				</Box>
			</Dialog>
		</Container>
	);
};

export default Shop;
